import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAuth, IMe, INotifications, INotificationsState } from '../../types'
import { API, setApiAuthorizationHeader } from '../services/API'
import { toast } from 'react-toastify'
import i18n from '../../i18n'
import axios, { Axios } from 'axios'


const token = localStorage?.getItem('rbc-access-token')
const initialState: INotificationsState = {
    notifications: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    unseenCount: null,
    changeLastSeen: null,
    pushNotification: null
}
export const getMyNotifications = createAsyncThunk('notification/get-my-notifications', async (data?: any) => {
    const token = localStorage?.getItem('rbc-access-token');
    setApiAuthorizationHeader(token)
    const response = await API.get(data.page ? `/get-my-notifications/?page=${data.page}` : `/get-my-notifications/`)
    return { ...response.data, page: data.page }
})
export const unseenNotificationsCount = createAsyncThunk('notification/unseen-notifications-count', async () => {
    const token = localStorage?.getItem('rbc-access-token');
    setApiAuthorizationHeader(token)
    const response = await API.get('/unseen-notifications-count/')
    return response.data
})
export const changeLastSeen = createAsyncThunk('notification/change-last-seen/', async (id: number) => {
    const token = localStorage?.getItem('rbc-access-token');
    setApiAuthorizationHeader(token)
    const response = await API.get(`change-last-seen/${id}/`)
    return response.data
})
export const readNotifications = createAsyncThunk('notification/read-notifications/', async (data: any) => {
    const token = localStorage?.getItem('rbc-access-token');
    setApiAuthorizationHeader(token)
    const response = await API.post(`read-notifications/`, data)
    return response.data
})
export const exchangerates = createAsyncThunk('notification/exchangerates/', async () => {
    // const token = localStorage?.getItem('rbc-access-token');
    // setApiAuthorizationHeader(token)
    let data = '<?xml version="1.0" encoding="utf-8"?>\r\n            <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">\r\n                <soap12:Body>\r\n            <ExchangeRatesByDate xmlns="http://www.cba.am/">\r\n                <date>2024-03-14</date>\r\n                </ExchangeRatesByDate>\r\n                </soap12:Body>\r\n                </soap12:Envelope>';

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://api.cba.am/exchangerates.asmx?op=ExchangeRatesByDate',
        headers: {
            'Content-Type': 'text/xml',
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExNTYwOTI3LCJpYXQiOjE3MTE1MTg5MjcsImp0aSI6IjNiMjEyZjk5ODc3ZjRlNzJiNjZlMWYxNTk4NjMzMmE1IiwidXNlcl9pZCI6Mjk4fQ.-HPxUQfD3fd_Yom-0MA-cVpA6EBvN0mn-q5-wxGgjfU'
        },
        data: data
    };
    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
})


const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {
        setNotification(state, action: PayloadAction<string>) {
            state.pushNotification = action.payload
        },
        changeUnseenCount(state) {
            state.unseenCount = {count: 0}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMyNotifications.fulfilled, (state: any, action: any) => {
            state.notifications = {
                ...action.payload,
                results: action.payload.page
                    ? [...state.notifications.results, ...action.payload.results]
                    : [...action.payload.results]
            }
        })
        builder.addCase(unseenNotificationsCount.fulfilled, (state, action) => {
            state.unseenCount = { ...action.payload }
        })
        builder.addCase(changeLastSeen.fulfilled, (state, action) => {
            state.changeLastSeen = { ...action.payload }
        })
        builder.addCase(readNotifications.fulfilled, (state, action) => {
            state.changeLastSeen = { ...action.payload }
        })

    },
})

export const { setNotification, changeUnseenCount } = notificationSlice.actions

export default notificationSlice.reducer