import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomDayPicker,
  DashbordSelect,
} from "../../../Components";
import { useGetCompaniesQuery } from "../../../store/services/companies";
import { useAppSelector } from "../../../store";
import { CreateTask } from "../../../Dialogs";
import styles from "./dashboard.module.scss";
import DashboardCard from "../../../Components/DashboardCard";
import { useGetTasksQuery, useGetTasksTypeQuery, usePrefetch } from "../../../store/services/tasks";
import moment from "moment";
import { Pagination, Stack } from '@mui/material';
import { Toaster } from "react-hot-toast";

export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth: any = useAppSelector((state) => state.auth);
  const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
  const [filter, setFilter] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  // const { data: clients } = useGetClientsQuery({ limit: 10, offset: 0 });
  const { data: companies } = useGetCompaniesQuery({ limit: 100000 });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  const { data, isLoading } = useGetTasksQuery(filter?.from_date ? {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter,
    from_date: filter?.from_date ? filter?.from_date?.toISOString() : '',
    to_date: filter?.to_date ? filter?.to_date?.toISOString() : ''
  } : {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter
  });
  const taskData = useAppSelector((state: any) => state.createTask.taskData);
  const prefetchPage = usePrefetch('getTasks');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    switch (taskData.task_type_id.toString()) {
      case "1":
        return navigate("/profile/companies/create-task");
      case "2":
        return navigate("/profile/companies/create-vacation");
      case "3":
        navigate("/profile/companies/create-provision-allowance");
        break;
      case "4":
        navigate("/profile/companies/create-dismissal");
        break;
      case "5":
        navigate("/profile/companies/change-salary-position");
        break;
      case "6":
        navigate("/profile/companies/create-bonus");
        break;
      case "7":
        navigate("/profile/companies/landing-bill");
        break;
      case "8":
        navigate("/profile/companies/estimate-task");
        break;
      case "9":
        navigate("/profile/companies/create-invoice");
        break;
      default:
        break;
    }
    setOpen(false);
  };
  const handleSelectDateRange = useCallback((value: any) => {
    setFilter({ ...filter, from_date: value?.from, to_date: value?.to })
  }, [filter]);
  const handleChangeFilter = useCallback((value: any) => {
    const keys = Object.keys(value)
    if (value[keys[0]]) {
      setFilter({ ...filter, ...value })
    } else {
      const filterClone = { ...filter };
      delete filterClone[keys[0]]
      setFilter({ ...filterClone })
    }
  }, [filter])
  const userRole = localStorage.getItem("rbcRole");

  useEffect(() => {
    if (notification?.data?.push_type !== 'TASK_COMMENT') {
      prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
    }
  }, [notification])
  useEffect(() => {
    const myParams: any = {}
    const queryParams: any = new URLSearchParams(window.location.search)
    for (const [key, value] of queryParams) {
      myParams[key] = value
    }
    setFilter({ ...myParams })
  }, [window.location.search])
  return (
    <div className={`w-100 dashboard`}>
      <Toaster />
      <div className="d-flex justify-content-between">
        <CreateTask
          open={open}
          handleClose={handleClose}
          handleOpen={handleClickOpen}
        />
        {userRole !== "2" && (
          <div className={styles.createTask} onClick={() => handleClickOpen()}>{t('createTasks')}</div>
        )}
      </div>
      <div className={`d-flex flex-wrap align-items-end gap-2 w-100 ${styles.filterRightSideCont}`}>
        {/* <SearchInput onClick={() => console.log("dddddd")} /> */}
        <DashbordSelect
          showValue={true}
          label={t('status')}
          value={filter.status?filter.status:0}
          list={[{ name: `${t('created')}`, id: 'created' },
          { name: `${t('answeredByAccountant')}`, id: 'answeredByAccountant' },
          { name: `${t('dispute')}`, id: 'dispute' },
          { name: `${t('done')}`, id: 'done' },
          { name: `${t('deleted')}`, id: 'deleted' },
          ]}
          onChange={(e) => handleChangeFilter({ status: e })}
        />
        <DashbordSelect
          showValue={true}
          label={t('companies')}
          value={filter.company_id?filter.company_id:0}
          list={companies?.filter((item: any) => item?.is_active)?.map((el: any) => ({ name: el?.name, id: el?.id }))}
          onChange={(e) => handleChangeFilter({ company_id: e })}
        />
        <DashbordSelect
          showValue={true}
          label={t('taskTypes')}
          value={filter.type_id?filter.type_id:0}
          list={task_types?.map((el: any) => ({ name: t(`${el?.name}`), id: el?.id }))}
          onChange={(e) => handleChangeFilter({ type_id: e })}
        />
        {/* <DashbordSelect 
        showValue={true}
        label={t('clients')}
        value={filter.client_id} 
        list={clients?.map((el:any)=>({name:`${el?.user?.first_name} ${el?.user?.last_name}`, id:el?.id}))} 
        onChange={(e) => handleChangeFilter({client_id:e})} 
        /> */}
        <CustomDayPicker
          className='dashboardPicker'
          mode="range"
          title={t('date')}
          selected={{ from: filter.from_date, to: filter.to_date }}
          submited={false}
          setSelected={handleSelectDateRange}
        />
      </div>

      <div className={`${styles.DashboardCardCont}`}>
        {data?.results?.map((el: any, ind: number) => (
          <DashboardCard
            key={ind}
            task_name={el.task_name}
            task_type={t(`${el.task_type.name}`)}
            id={el.id}
            status={el.status}
            company_name={el?.company?.name}
            created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
            manager=""
          />
        ))}
      </div>
      {data?.count > 10 && (
        <Stack spacing={2} marginTop={10}>
          <Pagination
            count={Math.ceil(data?.count / 10)}
            page={activePage}
            showFirstButton
            showLastButton
            onChange={(_, page) => {
              setActivePage(page);
            }}
          />
        </Stack>
      )}
      {!isLoading && data.results.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>}

    </div>
  );
}
