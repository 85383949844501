import React, { useCallback, useEffect, useState } from "react";
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomDayPicker,
  DashbordSelect,
} from "../../../Components";
import { CreateTask } from "../../../Dialogs";
import DashboardCard from "../../../Components/DashboardCard";
import { useGetTasksQuery, useGetTasksTypeQuery, usePrefetch } from '../../../store/services/tasks';
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { Pagination, Stack } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useGetCompaniesForAccountantQuery } from "../../../store/services/companies";
import { useGetManagersQuery } from "../../../store/services/managers";
import { ICompanyItem, IFilter, IMe, ITask, ITaskTypes } from "../../../types";
import { onMessageListener } from "../../../firebaseNotifications/firebase";
import { useAppSelector } from "../../../store";
import toast, { Toaster } from "react-hot-toast";

export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth: any = useAppSelector((state) => state.auth);
  const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
  const [filter, setFilter] = useState<IFilter>({});
  const [open, setOpen] = useState(false);
  const { pathname, search } = useLocation();
  const [activePage, setActivePage] = React.useState<number>(1);
  const { data: companies } = useGetCompaniesForAccountantQuery({ limit: 100000 });
  const { data: employee, isLoading: isLoadingEmployee } = useGetManagersQuery({ limit: 1000 });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });

  const { data, isLoading } = useGetTasksQuery(filter?.from_date ? {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter,
    from_date: filter?.from_date ? filter?.from_date?.toISOString() : '',
    to_date: filter?.to_date ? filter?.to_date?.toISOString() : ''
  } : {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter
  });
  const prefetchPage = usePrefetch('getTasks');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/profile/companies/create-task')
  };
  const handleSelectDateRange = useCallback((value: { from: Date, to: Date }) => {
    let newFilter = { ...filter, from_date: value?.from, to_date: value?.to }
    setFilter(newFilter)
    const query = queryString.stringify(newFilter)
    navigate(`${pathname}?${query}`, { replace: true })
  }, [filter]);
  const handleChangeFilter = useCallback((value: IFilter) => {
    const keys = Object.keys(value);
    let newFilter = {}
    if (value[keys[0] as keyof typeof filter]) {
      newFilter = { ...filter, ...value }
    } else {
      const filterClone = { ...filter } as typeof filter;
      const key: string = keys[0];
      delete filterClone[`${key as keyof typeof filter}`]
      newFilter = { ...filterClone }
    }
    setFilter({ ...newFilter })

    const query = queryString.stringify(newFilter)
    navigate(`${pathname}?${query}`, { replace: true })

  }, [filter]);

  useEffect(() => {
    if (notification?.data?.push_type !== 'TASK_COMMENT') {
      prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
    }
  }, [notification]);

  useEffect(() => {
    const myParams: IFilter = {}
    const queryParams: any = new URLSearchParams(window.location.search)
    for (const [key, value] of queryParams) {
      myParams[key as keyof typeof filter] = value
    }
    setFilter({ ...myParams })
  }, [window.location.search])

  return (
    <div className={`w-100 dashboard`}>
      <Toaster />
      <div className="d-flex justify-content-between">
        <CreateTask
          open={open}
          handleClose={handleClose}
          handleOpen={handleClickOpen}
        />
      </div>
      <div className={`d-flex flex-wrap align-items-end gap-2 w-100 ${styles.filterRightSideCont}`}>
        {/* <SearchInput onClick={() => console.log("dddddd")} /> */}
        <DashbordSelect
          showValue={true}
          label={t('status')}
          value={filter.status?filter.status:0}
          list={[{ name: `${t('created')}`, id: 'created' },
          { name: `${t('answeredByAccountant')}`, id: 'answeredByAccountant' },
          { name: `${t('dispute')}`, id: 'dispute' },
          { name: `${t('done')}`, id: 'done' },
          { name: `${t('deleted')}`, id: 'deleted' },
          ]}
          onChange={(e) => handleChangeFilter({ status: e } as IFilter)}
        />
        <DashbordSelect
          showValue={true}
          label={t('companies')}
          value={filter.company_id ? +filter?.company_id : 0}
          list={companies?.results?.filter((item: any) => item?.is_active)?.map((el: ICompanyItem) => ({ name: el?.name, id: el?.id }))}
          onChange={(e) => handleChangeFilter({ company_id: +e } as IFilter)}
        />
        <DashbordSelect
          showValue={true}
          label={t('taskTypes')}
          value={filter?.type_id ? +filter?.type_id : 0}
          list={task_types?.map((el: ITaskTypes) => ({ name: t(`${el?.name}`), id: el?.id }))}
          onChange={(e) => handleChangeFilter({ type_id: +e } as IFilter)}
        />
        <DashbordSelect
          showValue={true}
          label={t('employees')}
          value={filter?.a_w_id ? +filter?.a_w_id : 0}
          list={employee?.map((el: IMe) => ({ name: `${el?.user?.first_name} ${el?.user?.last_name}`, id: el?.id }))}
          onChange={(e) => handleChangeFilter({ a_w_id: +e } as IFilter)}
        />
        <CustomDayPicker
          className='dashboardPicker'
          mode="range"
          title={t('date')}
          selected={{ from: filter.from_date, to: filter.to_date }}
          submited={false}
          setSelected={handleSelectDateRange}
        />
      </div>
      <div className={`${styles.DashboardCardCont}`}>
        {data?.results?.map((el: ITask, ind: number) => {

          return <DashboardCard
            key={ind}
            task_name={el.task_name}
            task_type={t(`${el.task_type.name}`)}
            id={el.id}
            status={el.status}
            company_name={el?.company?.name}
            created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
            manager=''
          />
        })}
      </div>
      {data?.count > 12 && (
        <Stack spacing={2} marginTop={10}>
          <Pagination
            count={Math.ceil(data?.count / 12)}
            page={activePage}
            showFirstButton
            showLastButton
            onChange={(_, page) => {
              setActivePage(page);

            }}
          />
        </Stack>
      )}
      {!isLoading && data?.results?.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>}

    </div>
  );
}
