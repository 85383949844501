import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BankScore, CustomButtosWithIcon, CustomDayPicker, CustomInput, CustomRadio, CustomSelectFormik } from '../../../Components';
import BackCard from '../../../Components/SvgCompoents/BackCard';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import Passport from '../../../Components/SvgCompoents/PassportCard';
import styles from './CreateTask.module.scss';
import Recording from '../../../Components/Recording';
import { useAddTasksMutation, useGetbankQuery } from '../../../store/services/tasks';
import { useAppSelector } from '../../../store';
import { useAddImagesMutation } from '../../../store/services/photo';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import { toast } from 'react-toastify';
import { IBankItem, IFiles } from '../../../types';

export default function CreateTask() {
  const [submited, setSubmited] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const taskData = useAppSelector((state) => state.createTask.taskData);
  const [recording, setRecording] = useState<string>();
  const [files, setFiles] = useState<IFiles>({});
  const [dateContract, setDateContract] = useState<string>();
  const [dateStarting, setDateStarting] = useState<string>();
  const { data: banks } = useGetbankQuery({ limit: 10, offset: 0 });
  const [addImages, { data }] = useAddImagesMutation();
  const [addTasksRequest] = useAddTasksMutation();
  interface Values {
    country: string,
    type_document: string,
    bank_score?: number,
    bank?: number,
    role: string,
    address: string,
    salary?: number,
    comment?: string,
    // available: string,
    passport_front: string,
    passport_back: string,
    id_front: string,
    id_back: string,
    social?: number,
    passport_other: string,
    address_other: string,
    social_other: string,
    residence_other: string,
    recording: string,
    aplication_file: string,
    any_document: string[],
    intership: string,
    aplication: string
  }
  const handleDeleteFile = useCallback((name: keyof typeof files) => {
    setFiles((prev: IFiles) => {
      const prevFiles = { ...prev };
      delete prevFiles[name];
      return prevFiles
    })
  }, [])
  const handleDeleteAnyDocument = useCallback((ind: number) => {
    const prevFilesAnyDocument = files?.any_document ? [...files?.any_document] : [];
    prevFilesAnyDocument.splice(ind, 1)
    setFiles({ ...files, any_document: prevFilesAnyDocument })
}, [files])
  const handleRecording = useCallback((url: string) => {
    setRecording(url)
  }, [])
  const handleSelectDateContract = useCallback((value: string) => {
    setDateContract(value)
  }, [])
  const handleSelectDateStarting = useCallback((value: string) => {
    setDateStarting(value)
  }, [])
  const handleSetFile = useCallback((data: IFiles) => {
    setFiles({ ...files, ...data })
  }, [files])
  const SignupSchema = Yup.object().shape({
    country: Yup.string()
      .required(t('required')),
    type_document: Yup.string()
      .required(t('required')),
    task_name: Yup.string()
      .required(t('required')),
    salary: Yup.number()
      .required(t('required'))
      .min(0, t('required')),
    role: Yup.string()
      .required(t('required')),
    address: Yup.string()
      .required(t('required')),
    email: Yup.string().email(t('emailError'))
  });

  return (
    <div className={`w-100`}>
      <Formik
        initialValues={{
          country: '0',
          type_document: '0',
          role: '',
          address: '',
          comment: '',
          // available: '',
          passport_front: '',
          passport_back: '',
          id_front: '',
          id_back: '',
          passport_other: '',
          address_other: '',
          social_other: '',
          residence_other: '',
          recording: '',
          aplication_file: '',
          aplication: 'false',
          any_document: [],
          intership: 'false'
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          const {bank, ...rest}=values;
          if (((values.country === '0' && files?.passport_back && files?.passport_front && values.social)
            || (values.country === '1' && files.social_other && files.address_other && files.passport_other && files.residence_other)
            || (values.aplication === '1' && files.application)
            || (values.country === '0' && files.id_back && files.id_front) && dateContract && dateStarting)
          ) {
            //         localStorage.setItem('cmpId', taskData.company_id);
            // localStorage.setItem('taskTypeId', taskData.task_type_id);
            addTasksRequest({
              bank: bank?bank:'',
              bank_score: '',
              social: '',
              ...rest,
              recording: recording ? recording : '',
              status: 'created',
              task_type: taskData?.task_type_id ? taskData?.task_type_id : localStorage.getItem('taskTypeId'),
              company: taskData?.company_id ? taskData?.company_id : localStorage.getItem('cmpId'),
              intership: values.intership === "true",
              country: values.country === '0' ? 0 : 1,
              type_document: values.type_document === '0' ? 0 : 1,
              date_contract: dateContract,
              date_starting: dateStarting,
              ...files
            }).then((res: any) => {
              if (res?.data?.id) {
                toast.success('Գործընթացը հաջողությամբ կատարվել է');
                navigate('/profile/dashboard')
              }
            })
          }

        }}
      >
        {({ errors, touched, values, isSubmitting, handleChange }: any) => {
          return <Form className='w-100 position-relative' >
            <div className={`d-flex justify-content-between ${styles.headingButton}`}>
              <h1 className={styles.pageHeading}>{t(`EmployeeRegistration`)}</h1>
              <div className='d-none d-md-flex'><CustomButtosWithIcon
                title={t('create')}
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                </svg>}
                type='submit'
                onClick={() => setSubmited(true)} />
              </div>
            </div>
            <CustomInput name='task_name' title={t('taskName')} className='taskInput' />
            <div style={{ maxWidth: '770px' }} className='d-flex flex-column flex-md-row justify-content-between'>
              <CustomRadio
                radioArray={[{ label: t('armenia'), value: '0' }, { label: t('anotherCountry'), value: '1' }]}
                label={t('WhereAreYouFrom')}
                name={'country'}
              />
              {values.country === '0' && <CustomRadio
                radioArray={[{ label: t('passport'), value: '0' }, { label: t('IdCard'), value: '1' }]}
                label={t('chooseTypeDocument')}
                name={'type_document'}
              />}
            </div>
            <div className={styles.cardUpload}>
              <div className={styles.sectionHeading}>{t('attachYourDocument')}</div>

              {values.country === '0'
                ? <>
                  {values.type_document === '0' && <div className={`d-flex ${styles.cardLine}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input
                        type='file'
                        style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ passport_front: res.data })
                                }
                              })
                          }
                        }}
                      />
                      {files?.passport_front ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('passport_front')}
                        img={`${process.env.REACT_APP_API_KEY}${files.passport_front}`} /> : <Passport />}

                      <span className={styles.cardTitle}>{t('passportFrontScreen')}</span>
                      {submited && !files?.passport_front && <div className='error error-text'>{t('required')}</div>}
                    </label>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ passport_back: res.data })
                                }
                              })
                          }
                        }} />
                      {files?.passport_back ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('passport_back')}
                        img={`${process.env.REACT_APP_API_KEY}${files.passport_back}`} /> : <BackCard />}
                      <span className={styles.cardTitle}>{t('passportBackScreen')}</span>
                      {submited && !files?.passport_back && <div className='error error-text'>{t('required')}</div>}
                    </label>
                  </div>}
                  {values.type_document === '0' && <div className={`d-flex flex-column  position-relative `}>
                  <div className={`d-flex flex-wrap w-100 ${styles.inputCont}`}>
                    <CustomInput name='social' title={t('socialOther')} type='number' className='taskInput' />
                    </div>
                    {submited && values.country && (!values.social || values.social<=0) && <div className='error error-text position-absolute bottom-0'>{t('required')}</div>}
                  </div>}
                  {values.type_document !== '0' && <div className={`d-flex ${styles.cardLine}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                        if (file) {
                          const formdata = new FormData();
                          formdata.append('file_url', file)
                          addImages(formdata)
                            .then((res: any) => {
                              if (res.data) {
                                handleSetFile({ id_front: res.data })
                              }
                            })
                        }
                      }} />
                      {files?.id_front ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('id_front')}
                        img={`${process.env.REACT_APP_API_KEY}${files.id_front}`} /> : <DocumentCard />}
                      <span className={styles.cardTitle}>{t('IDFrontScreen')}</span>
                      {submited && !files?.id_front && <div className='error error-text'>{t('required')}</div>}
                    </label>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                        if (file) {
                          const formdata = new FormData();
                          formdata.append('file_url', file)
                          addImages(formdata)
                            .then((res: any) => {
                              if (res.data) {
                                handleSetFile({ id_back: res.data })
                              }
                            })
                        }
                      }} />
                      {files?.id_back ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('id_back')}
                        img={`${process.env.REACT_APP_API_KEY}${files.id_back}`} /> : <DocumentCard />}
                      <span className={styles.cardTitle}>{t('IDBackScreen')}</span>
                      {submited && !files?.id_back && <div className='error error-text'>{t('required')}</div>}
                    </label>
                  </div>}
                </>
                : <>
                  <div className={`d-flex ${styles.cardLine}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ passport_other: res.data })
                                }
                              })
                          }
                        }} />
                      {files?.passport_other ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('passport_other')}
                        img={`${process.env.REACT_APP_API_KEY}${files.passport_other}`} /> : <DocumentCard />}
                      <span className={styles.cardTitle}>{t('passportOther')}</span>
                      {submited && !files?.passport_other && <div className='error error-text'>{t('required')}</div>}
                    </label>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ address_other: res.data })
                                }
                              })
                          }
                        }} />
                      {files?.address_other ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('address_other')}
                        img={`${process.env.REACT_APP_API_KEY}${files.address_other}`} /> : <DocumentCard />}
                      {submited && !files?.address_other && <div className='error error-text'>{t('required')}</div>}
                      <span className={styles.cardTitle}>{t('addressOther')}</span>
                    </label>
                  </div>
                  <div className={`d-flex ${styles.cardLine}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ social_other: res.data })
                                }
                              })
                          }
                        }}
                      />
                      {files?.social_other ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('social_other')}
                        img={`${process.env.REACT_APP_API_KEY}${files.social_other}`} /> : <DocumentCard />}
                      <span className={styles.cardTitle}>{t('socialOther')}</span>
                      {submited && !files?.social_other && <div className='error error-text'>{t('required')}</div>}
                    </label>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ residence_other: res.data })
                                }
                              })
                          }
                        }}
                      />
                      {files?.residence_other ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('residence_other')}
                        img={`${process.env.REACT_APP_API_KEY}${files.residence_other}`} /> : <DocumentCard />}
                      <span className={styles.cardTitle}>{t('residenceOther')}</span>
                      {submited && !files?.residence_other && <div className='error error-text'>{t('required')}</div>}
                    </label>
                  </div>
                </>}
            </div>
            <div className={`d-flex flex-wrap w-100 ${styles.inputCont}`}>
              <CustomInput name='email' title={t('emailAdress')} className='taskInput' />
              {/* <CustomInput name='bank_score' title={t('bankScore')} className='taskInput' /> */}
              <BankScore
                values={values}
                name='bank_score'
                second_name='bank'
                title={t('bankScore')}
                list={banks?.map((el: IBankItem) => ({
                  src: `${process.env.REACT_APP_API_KEY}${el?.icon}`,
                  id: el?.id,
                  name: el?.name
                }))}
                className='taskInput'
              />
              {/* <CustomInput name='bank' title={t('bankScore')} /> */}
              {/* <CustomSelectFormik
                label={t("bank")}
                list={banks?.map((el: any) => ({
                  name: `${el?.name}`,
                  id: el.id,
                }))}
                name='bank'
              /> */}
              <CustomInput name='role' title={t('role')} className='taskInput' />
              <CustomInput name='salary' title={t('salary')} type='number' className='taskInput' />
              <CustomInput name='address' title={t('address')} type='text' className='taskInput' />
              <CustomDayPicker
                disabled={{ from: new Date(2000, 1, 1), to: new Date() }}
                mode='single'
                setSelected={handleSelectDateContract}
                name='date_contract'
                title={t('dataContract')}
                className='taskInput'
                selected={dateContract}
                submited={submited}
              />
              <CustomDayPicker
                disabled={{ from: new Date(2000, 1, 1), to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1) }}
                mode='single'
                setSelected={handleSelectDateStarting}
                name='date_starting'
                title={t('dataStarting')}
                className='taskInput'
                selected={dateStarting}
                submited={submited}
              />
              {/* <CustomInput name='date_contract' type='date' title={t('dataContract')} className='taskInput' />
              <CustomInput name='date_starting' type='date' title={t('dataStarting')} className='taskInput' /> */}
            </div>
            <div className='d-flex flex-wrap justify-content-between'>
              <div className='w-100'>
                <CustomRadio
                  radioArray={[{ label: t('on'), value: '1' }, { label: t('off'), value: '0' }]}
                  label={t('accost')}
                  name={'aplication'}
                />
                <div>
                  <div className={`d-flex align-items-center ${styles.documentLabel}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ application: res.data })
                                }
                              })
                          }
                        }}
                      />
                      {files?.application ? <ComponentWithBorder
                        onClick={() => handleDeleteFile('application')}
                        img={`${process.env.REACT_APP_API_KEY}${files.application}`} /> : <DocumentCard />}
                      {submited && !files?.application && values.aplication === '1' && <div className='error error-text'>{t('required')}</div>}
                    </label>
                    <span className={styles.label}>{t('attachFilesHere')}</span>
                  </div>
                </div>
              </div>
              <div className='w-100'>
                <CustomRadio
                  radioArray={[{ label: t('yes'), value: 'true' }, { label: t('no'), value: 'false' }]}
                  label={t('intership')}
                  name={'intership'}
                />
                <div>
                  {/* <div className={`d-flex align-items-center ${styles.documentLabel}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column`}>
                      <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file:File | null = (e?.target?.files &&  e?.target?.files?.length>0) ? e?.target?.files[0]:null;
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                console.log(res, 'resresresres123');
                                if (res.data) {
                                  let any_document = files.any_document ? [...files.any_document] : [];
                                  any_document.push(res.data)
                                  handleSetFile({ any_document })
                                }
                              })
                          }
                        }}
                      />
                      <DocumentCard />
                    </label>
                    <span className={styles.label}>{t('attachFilesHere')}</span>
                  </div> */}
                  <CustomInput name='comment' title={t('anyText')} className='taskInput' />
                </div>
              </div>
              <div>
                <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}>{t('attachYourDocument')}</div>
                <div className={`d-flex align-items-center ${styles.documentLabel}`}>
                  <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                    <input type='file' style={{ display: 'none' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                        if (file) {
                          const formdata = new FormData();
                          formdata.append('file_url', file)
                          addImages(formdata)
                            .then((res: any) => {
                              if (res.data) {
                                let any_document = files && files.any_document ? [...files.any_document] : [];
                                any_document.push(res.data)
                                handleSetFile({ any_document })
                              }
                            })
                        }
                      }}
                    />
                    <DocumentCard />
                  </label>
                  <span className={styles.label}>{t('attachFilesHere')}</span>
                </div>
                {files?.any_document && files?.any_document?.length > 0 && <div className='d-flex gap-2'>
                  {files?.any_document?.map((el: string, ind: number) => {
                    return <ComponentWithBorder
                    onClick={() => handleDeleteAnyDocument(ind)}
                      key={ind}
                      img={`${process.env.REACT_APP_API_KEY}${el}`} />
                  })}
                </div>}
              </div>
            </div>
            <div>
              <div className={styles.sectionHeading}>{t('voiceMessage')}</div>
              <Recording handleRecording={handleRecording} />
            </div>
          </Form>
        }}
      </Formik>
    </div >

  );
}


